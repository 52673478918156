import React, { Suspense, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Container } from "./components/layout/Container";
import { CookieConsent } from "./components/misc/CookieConsent/CookieConsent";

// Global styles
import { ErrorBoundary } from "react-error-boundary";
import { PhotoProvider } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { Spinner } from "./components/loaders/Spinner";
import Curtain from "./components/misc/Curtain";
import { WindowResizeListener } from "./components/utils/WindowResizeListener";
import { AuthProvider } from "./contexts/Auth";
import { LanguageProvider } from "./contexts/Language";
import { ErrorFallbackScreen } from "./routes/error/ErrorFallbackScreen";
import "./styles/main.scss";
import { useDebounce } from "./utils/hooks";
import trackingUtils from "./utils/trackingUtils";

export const AppContext = React.createContext({});

export const App = () => {
    const location = useLocation();
    const [showSidebar, setShowSidebar] = useState(false);
    const [hasSidebar, setHasSidebar] = useState(false);
    const [pageInfo, setPageInfo] = useState({
        windowWidth: typeof window !== "undefined" ? window.innerWidth : 0,
        windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
        breakpoint: null
    });
    const [scrollContainerElement, setScrollContainerElement] = useState(document.body);

    useDebounce(
        () => {
            trackingUtils.trackPageView(location);
        },
        [location],
        { delay: 100 }
    );

    return (
        <LanguageProvider>
            <AuthProvider>
                <ErrorBoundary FallbackComponent={ErrorFallbackScreen}>
                    <Suspense fallback={<Curtain />}>
                        <AppContext.Provider
                            value={{
                                showSidebar,
                                setShowSidebar,
                                hasSidebar,
                                setHasSidebar,
                                pageInfo,
                                setPageInfo,
                                scrollContainerElement,
                                setScrollContainerElement
                            }}
                        >
                            <PhotoProvider loadingElement={<Spinner contrast />}>
                                <Container>
                                    <Outlet />
                                    {/* Cookie consent */}
                                    {["/"].includes(location.pathname) && <CookieConsent />}
                                </Container>
                            </PhotoProvider>
                            <WindowResizeListener />
                        </AppContext.Provider>
                    </Suspense>
                </ErrorBoundary>
            </AuthProvider>
        </LanguageProvider>
    );
};
