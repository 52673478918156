import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../components/button/Button";
import { ErrorScreen } from "../../components/screens/ErrorScreen";
import { useNavigate } from "react-router-dom";

const msgKey = "errorFallbackScreen";

export const ErrorFallbackScreen = ({ resetErrorBoundary }) => {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate("/me", {
            replace: true
        });
        resetErrorBoundary();
    };

    return (
        <ErrorScreen
            title={<FormattedMessage id={`${msgKey}.title`} />}
            message={<FormattedMessage id={`${msgKey}.message`} />}
        >
            <Button onClick={handleContinue}>
                <FormattedMessage id={`${msgKey}.continue`} />
            </Button>
        </ErrorScreen>
    );
};
