import * as Sentry from "@sentry/browser";

/**
 * Tracking utilities
 */

import config from "../config";

const trackingUtils = {
    trackPageView: (location) => {
        const title = location?.pathname
            ?.split("/")
            .reduce((acc, curr) => {
                if (curr && isNaN(curr)) {
                    return `${acc}${acc !== "" ? "/" : ""}${curr}`;
                } else {
                    return acc;
                }
            }, "")
            .trim();
        const path = location?.path || "";

        // Google Analytics
        if (typeof window.gtag !== "undefined") {
            window.gtag("event", "page_view", {
                page_title: title,
                page_location: path
            });
        }

        // Facebook Pixel
        if (typeof window.fbq !== "undefined") {
            window.fbq("track", "PageView");
        }
    },

    trackEvent: ({ name, params }) => {
        if (typeof window.gtag !== "undefined") {
            window.gtag("event", name, params || {});
        }
    },

    setUser: (user) => {
        // Sentry
        Sentry.setUser(user ? { id: user.id, username: user.username } : null);

        // Google Analytics
        if (typeof window.gtag !== "undefined" && user?.id) {
            window.gtag("config", config.GOOGLE_TAG_ID, {
                user_id: user.id,
                user_properties: {
                    locale: user.locale,
                    mobileCountryCode: user.mobileCountryCode
                }
            });
        }
    }
};

export default trackingUtils;
