import * as Sentry from "@sentry/react";
import moment from "moment";
import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { App } from "./App";
import { AuthenticatedRoute } from "./components/route/AuthenticatedRoute";
import { DATE_TIME_FORMAT } from "./constants";
import config from "./config";

// Images
import "./assets/images/vp-app-icon.png";
import "./assets/images/vp-email-logo.png";
import "./assets/images/vp-maskable-icon.png";
import "./assets/images/vp-sharing-image.jpeg";

// Moment config
moment.defaultFormat = DATE_TIME_FORMAT;
moment.defaultFormatUtc = DATE_TIME_FORMAT;

// Vibrate
navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

/**
 * ROUTING
 * --------------------------------------------------------
 */
const Front = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/front/Front").then((module) => ({ default: module.Front }))
);
const Pricing = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/front/Pricing").then((module) => ({ default: module.Pricing }))
);
const Contact = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/front/Contact").then((module) => ({ default: module.Contact }))
);
const Login = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/Login").then((module) => ({ default: module.Login }))
);
const SignUp = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/SignUp").then((module) => ({ default: module.SignUp }))
);
const SignUpContinue = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/SignUpContinue").then((module) => ({
        default: module.SignUpContinue
    }))
);
const ForgotPassword = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/ForgotPassword").then((module) => ({
        default: module.ForgotPassword
    }))
);
const SignOut = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/SignOut").then((module) => ({
        default: module.SignOut
    }))
);
const VerifyEmail = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/VerifyEmail").then((module) => ({
        default: module.VerifyEmail
    }))
);
const PageNotFound = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/boarding/PageNotFound").then((module) => ({
        default: module.PageNotFound
    }))
);

const Terms = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/misc/terms/Terms").then((module) => ({ default: module.Terms }))
);
const PrivacyPolicy = lazy(() =>
    import(/* webpackChunkName: "boarding" */ "./routes/misc/privacy/PrivacyPolicy").then((module) => ({
        default: module.PrivacyPolicy
    }))
);

const MeRoot = lazy(() =>
    import(/* webpackChunkName: "me" */ "./routes/me/MeRoot").then((module) => ({ default: module.MeRoot }))
);
const OrganizationRoot = lazy(() =>
    import(/* webpackChunkName: "org" */ "./routes/organization/OrganizationRoot").then((module) => ({
        default: module.OrganizationRoot
    }))
);
const CourseRoot = lazy(() =>
    import(/* webpackChunkName: "course" */ "./routes/course/CourseRoot").then((module) => ({
        default: module.CourseRoot
    }))
);
const VesselRoot = lazy(() =>
    import(/* webpackChunkName: "vessel" */ "./routes/vessel/VesselRoot").then((module) => ({
        default: module.VesselRoot
    }))
);
const AdminRoot = lazy(() =>
    import(/* webpackChunkName: "admin" */ "./routes/admin/AdminRoot").then((module) => ({
        default: module.AdminRoot
    }))
);

const UserLogReviewReport = lazy(() =>
    import(/* webpackChunkName: "reports" */ "./routes/reports/user_log_review/UserLogReviewReport").then((module) => ({
        default: module.UserLogReviewReport
    }))
);

const StyleGuideRoot = lazy(() =>
    import(/* webpackChunkName: "style-guide" */ "./routes/style-guide/StyleGuideRoot").then((module) => ({
        default: module.StyleGuideRoot
    }))
);

// Sentry
if (config.SENTRY_DSN) {
    console.log("[VP] Sentry enabled (%s)", config.ENV);
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.ENV,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampleRate: config.SENTRY_TRACE_SAMPLE_RATE || 0.1,
        replaysSessionSampleRate: config.SENTRY_REPLAY_SAMPLE_RATE || 0.1,
        replaysOnErrorSampleRate: 1.0
    });
}

// Create root element
const rootElement = document.createElement("div");
rootElement.id = "app";
document.body.appendChild(rootElement);

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<App />}>
            {/* Front */}
            <Route path="/" element={<Front />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<Contact />} />

            {/* Boarding */}
            <Route path="/signout" element={<SignOut />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signup/continue/:key" element={<SignUpContinue />} />
            <Route path="/forgot" element={<ForgotPassword />} />

            {/* Boarding (used by Auth server) */}
            <Route path="/login" element={<Login />} />

            {/* Course */}
            <Route path="/courses/*" element={<CourseRoot />} />

            {/* Misc */}
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />

            {/* Verifications */}
            <Route path="/verify/email/:key" element={<VerifyEmail />} />

            {/* Me */}
            <Route path="/me/*" element={<AuthenticatedRoute element={<MeRoot />} />} />

            {/* Organization */}
            <Route path="/org/:organizationId/*" element={<AuthenticatedRoute element={<OrganizationRoot />} />} />

            {/* Vessel */}
            <Route path="/vessels/:vesselId/*" element={<AuthenticatedRoute element={<VesselRoot />} />} />

            {/* Admin */}
            <Route path="/admin/*" element={<AuthenticatedRoute roles="admin" element={<AdminRoot />} />} />

            {/* Reports */}
            <Route path="/reports/user_log_review/:reviewUuid" element={<UserLogReviewReport />} />

            {/* Style guide */}
            <Route path="/style-guide/*" element={<StyleGuideRoot />} />

            {/* Page not found */}
            <Route element={<PageNotFound />} />
        </Route>
    )
);

ReactDOM.createRoot(document.getElementById("app")).render(<RouterProvider router={router} />);
