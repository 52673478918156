/**
 * Handle error from all services.
 * I had to implement this common method since
 * errors are proagated in the callchain which results
 * in error callbacks receiving errors from unrelated calls.
 *
 * @param error
 */

const errorHandler = (error) => {
    return error.response ? error.response.data : null;
};

export default errorHandler;
